import React, { useState } from "react"
import { Button, ButtonGroup, Table, ProgressBar, Form, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { MeetingActions } from '../../store/MeetingReducer';
import { MeetingSelector } from "../../store/selectors";
import { AppDispatch } from '../../store/store';
import { InvitedMeetingType } from "../../types/Dater";
import { SendEmailModal } from '../../components/sendEmail'
import { createId } from "../../api/createId";
import { DBcreateMeeting, DBaddParticipant, DBdeleteParticipant, DBupdateParticipant, DBisExistingMeeting, DBrequestExistingMeeting, DBaddlistParticipant, DBrequestMeeting, DBrequestMeetingParticipants } from '../../database/MeetingAction';
import { User } from "../../store/SessionReducer";


export type StatusInvited = "to_invite" | "invited" | "has_answered" | "has_validated"
const PrintedStatus = {
    "to_invite" : 'Pas encore invité',
    "invited": "en attente de ses disponibilités",
    "has_answered": "a répondu",
    "has_validated": "a validé un créneau"
}

type TableParticipantsProps = {
    id_meeting: string | undefined, 
    connectedUser: User| undefined, 
    isNew: boolean 
}

const originURL = process.env.URL_Origin as string;

export const TableParticipants = ( {id_meeting, connectedUser, isNew} : TableParticipantsProps ) => {
    
    const localMeetingPage = useSelector(MeetingSelector)
    const [modalEmailOn, setModalEmailOn] = useState(false);
    const handleCloseEmail = () => {setModalEmailOn(false)}

    const [modalEmailData, setModalEmailData] = useState({
        from : "no_reply@datime.pro",
        to_email : "impossible" ,
        personnal_link : originURL + "/error/"
    });

    const dispatch = useDispatch<AppDispatch>()

    const handleChange = (index:keyof InvitedMeetingType, value:any, invit:InvitedMeetingType)=>{
        // change l'invité concerné
        let id = invit.id 
        const idx = localMeetingPage['participants'].findIndex(x => x.id === id);
        const new_invited = [...localMeetingPage['participants']]
        const replaced_invited = {...new_invited[idx], ...{[index]:value} }
        new_invited[idx] = replaced_invited
        // pousse la nouvelle liste d'invité
        dispatch(MeetingActions.updateParticipant({ meetingId: id_meeting, invited: replaced_invited }))
        // pousse l'invité modifié s'il est dans la base (donc si la page est créée)
        // console.log('on pousse', replaced_invited)
        if (id_meeting) {
            DBupdateParticipant({ meetingId: id_meeting, invited: replaced_invited })
        }
    }

    const addTableRows = ()=>{
        const rowsInput:InvitedMeetingType = {
            'id': createId(),
            'id_person': createId(),
            'id_meeting':  id_meeting ? id_meeting : '',
            'added_by': connectedUser ? connectedUser.id : '',
            'mail':'',
            'status': 'to_invite',
            'mandatory': true,
            'nb_asked': 0,
            'duration': localMeetingPage['duration'],
            'title': ''
        } 
        dispatch(MeetingActions.addParticipant({ invited: rowsInput }))
    }
    
    const deleteTableRows = (index:any)=>{
        const rows = [...localMeetingPage['participants']];
        rows.splice(index, 1);
        dispatch(MeetingActions.createMeeting({ ...localMeetingPage, participants: rows }))
    }
    
    const row_invite = (invit: InvitedMeetingType) => {
        return(
            <tr key={invit['id']}>
                <td>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="email" placeholder= {invit['mail'] ? invit['mail'] : "name@example.com" }
                            onChange={(evnt)=>(handleChange("mail", evnt.target.value, invit))}  />
                        </Form.Group>
                    </Form>
                </td>
                <td>  { PrintedStatus[invit['status']] } </td>
                {/* <td><input type="text" value={invit["phone"] ? invit["phone"] : "*"}  onChange={(evnt)=>(handleChange("phone", evnt))} name="phone_number" className="form-control"/> </td> */}
                <td>
                    <ButtonGroup /*vertical*/>
                        <Button key={invit['id']} disabled={ !isNew } 
                            onClick={() => { 
                                setModalEmailData({
                                    from : connectedUser?.email ? connectedUser.email : "no_reply@datime.pro",
                                    to_email : invit["mail"] ? invit["mail"] : "impossible" ,
                                    personnal_link : originURL + "/invitation/" + id_meeting + '/' + invit["id"] 
                                })
                                console.log("Le lien que l'on va envoyer est :", modalEmailData['personnal_link'])
                                setModalEmailOn(true)
                                handleChange('status', 'invited', invit)
                            }
                        } >  Personnaliser le mail </Button>
                            
                        {/* <Button>Laisser DaTime inviter par sms</Button> */}
                    </ButtonGroup>
                </td>
                <td>  { invit['mandatory'] ? "Oui" : "Non"  } 
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            defaultChecked={true}
                            onChange={ (evnt)=> { 
                                handleChange("mandatory", evnt.target.checked, invit) 
                                if (localMeetingPage) {
                                    DBupdateParticipant({ meetingId: localMeetingPage['id'], invited: invit })
                                    dispatch(MeetingActions.updateParticipant({ meetingId: localMeetingPage['id'], invited: invit }))
                                }
                            }}
                        />
                        </Form>
                
                </td>
                <td><button className="btn btn-outline-danger" onClick={()=>{
                    deleteTableRows(invit['id'])
                    if (localMeetingPage) {
                        DBdeleteParticipant({ meetingId: localMeetingPage['id'], personId: invit['id'] })
                        dispatch(MeetingActions.deleteParticipant( { meetingId: localMeetingPage['id'], personId: invit['id'] } ))
                    }
                }
                }>
                        x
                    </button></td>
            </tr>
        )
    }


    return (

        <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Mail</th>
                        {/* <th>Téléphone</th> */}
                        <th>Statut</th>
                        <th>Action</th>
                        <th>Présence impérative</th>
                        <th>Supprimer</th>
                    </tr>
                </thead>
                <tbody>
                    <>
                        { localMeetingPage['participants'].map( (item, idx) => row_invite(item))}
                        <SendEmailModal
                                modalOn={modalEmailOn} toRemoveOn={false} handleClose={handleCloseEmail}
                                data = {modalEmailData}
                            />
                        <tr>
                            <td colSpan={100}>
                                <button className="btn btn-outline-success" onClick={addTableRows} >Ajouter un participant</button>
                            </td>
                        </tr>
                    </>
                </tbody>
            </Table>
    )
}
