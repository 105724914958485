
import { supabase } from '../components/auth/supabaseClient';
import type { TypeEvent } from '../types/Events';
import { convert_time_without_second, convert_date_to_utc_without_second } from './convert_to_db'


const typeEvent_to_supabase = (event: TypeEvent) => {
    const sb_event = {
        ...event,
        start: convert_date_to_utc_without_second(event.start), // date timestamptz
        end: convert_date_to_utc_without_second(event.end), // date timestamptz
        // startTime?: string, // date timetz
        // endTime?: string, // date timetz
    }
    if (sb_event['startTime']) { sb_event['startTime'] = convert_time_without_second(sb_event['startTime'] )}
    if (sb_event['endTime']) { sb_event['endTime'] = convert_time_without_second(sb_event['endTime'] )}
    return sb_event
}

export const DBcreateEvent = async (plainEventObject: TypeEvent) => {
    const { data, error } = await supabase
        .from('events')
        .insert([typeEvent_to_supabase(plainEventObject)])
    if (error) { throw { error }; } 
}


export const DBupdateEvent = async (plainEventObject: TypeEvent) => {
    const { data, error } = await supabase
        .from('events')
        .update({ ...plainEventObject })
        .eq('id', plainEventObject.id)

    if (error) { throw { error }; }
}


export const DBgetRoutineId = async (user_id: string, resourceId: string) => {
    const { data, error } = await supabase
        .from('events')
        .select('id')
        .eq('user_id', user_id)
        .eq('resourceId', resourceId)
        .returns<string[]>();

    if (error) { throw { error }; }
    return data
}

export const DBremoveRoutine = async (user_id: string, resourceId: string) => {
    
    const voir = DBgetRoutineId(user_id, resourceId).then( data => { return data })
    const { data, error } = await supabase
        .from('events')
        .delete()
        .eq('user_id', user_id)
        .eq('resourceId', resourceId)
        
    if (error) { throw { error }; }

}


export const DBdeleteEvent = async (eventId: string) => {
    const { data, error } = await supabase
        .from('events')
        .delete()
        .eq('id', eventId)
    if (error) { throw { error }; }
}


export const DBrequestEvents = async (range: any, userId:string) => {
    // avant on cherchait un event mais 
    // const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
    // if (errorAuth){
    //     console.log('Probleme dans getUser', errorAuth)
    // }
    // const userId = dataAuth.user?.id
    const { data: dataEvent, error:errorEvent } = await supabase
        .from('events')
        .select()
        .eq('user_id', userId)
        .returns<TypeEvent[]>();


    if (errorEvent) { throw { errorEvent }; }
    return dataEvent
}