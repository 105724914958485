import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useNavigate } from "react-router-dom";

import { supabase } from './auth/supabaseClient';
import { useEffect, useState } from 'react';

import SupabaseAuth from './auth/SupabaseAuth';
import { Link } from 'react-router-dom';

import logo from "../favicon-128.png"

function Navabar() {

    // utiliser le offcanva, c'est cool
    // la session étant gérée par ailleurs, ce n'est peut-être pas nécessaire de l'avoir
    const [session, setSession] = useState<null | any >(null)

    let navigate = useNavigate();

    const routeChange = () =>{ 
      let path = `/invitation`; 
      navigate(path);
    }

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        })

        const {
        data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    return (
        <>
        <Navbar bg="dark" expand="lg" style={{ fontSize: "large", justifyContent: 'center', alignContent:'center'}}>
            <Container>
                <Navbar.Brand style={{alignContent:'center'}} as={Link} to='/'>
                    <img
                        alt=""
                        src= {logo}
                        // src="../datetime/favicon-128.png"
                        width="70"
                        height="70"
                        className="d-inline-block align-center"
                    />
                    {' '}
                    DaTime
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="container-fluid">
                        <Nav.Link as={Link} to="/meeting">Organiser</Nav.Link>
    
                        {/* <NavDropdown title="Fixer" id="navbarScrollingAnswer">
                            // <NavDropdown.Item as={Link} to="invitation" key="27">Accueil invitation</NavDropdown.Item> 
                            <NavDropdown.Item as={Link} to="invitation" key="23">Mes préférences</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="availabilities" key="25">Mes disponibilités</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link as={Link} to="/availabilities">Définir des créneaux </Nav.Link>

                        {/* <Nav.Link as={Link} to="/meeting_decision">Valider </Nav.Link>
                        <Nav.Link as={Link} to="/rescheduling">Reprogrammer </Nav.Link> */}
        
                    </Nav>
                    <Nav>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Nav.Link as={Link} to="/faq">F.A.Q</Nav.Link>
                        <Nav.Link as={Link} to="/profile" > Mes préférences</Nav.Link>
                        <Navbar.Text >
                            <SupabaseAuth></SupabaseAuth>
                        </Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>   
        </>
    );
}


export default Navabar;