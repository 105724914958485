import { supabase } from '../components/auth/supabaseClient';
import type { MeetingType } from '../types/Meeting';
import { InvitedMeetingType } from '../types/Dater';

/// on a création, update, delete request pour Meeting et participant
/// on a aussi request meeting user
export const DBcreateMeeting = async (plainMeetingObject: MeetingType)  => {
    const { data, error } = await supabase
        .from('meetings')
        .insert(plainMeetingObject)

    if (error) { throw { error }; }
    // on n'utilise plus participants, on crée le meeting d'un côté et les participants ensuite
    // (ou en meme temps)
    plainMeetingObject['participants'].map( async (invited) =>  {
        const { data, error} = await supabase
            .from('meeting_person')
            .insert(
                { 
                    id_meeting: plainMeetingObject['id'],
                    id_person: invited['id'],
                    mail: invited['mail'],
                    mandatory: invited['mandatory'],
                    status: invited['status']
                    },
            )
        if (error) { throw { error }; }
    })
}

export const DBupdateMeeting = async (plainMeetingObject: MeetingType) => {
    const { data, error } = await supabase
        .from('meetings')
        .update({ ...plainMeetingObject })
        .eq('id', plainMeetingObject.id)

        if (error) { throw { error }; }

        // on n'utilise plus participants, on crée le meeting d'un côté et les participants ensuite
        // (ou en meme temps)
        
        // plainMeetingObject['participants'].map( async (invited)  =>  {
        //     const { data, error} = await supabase
        //         .from('meeting_person')
        //         .update([
        //             { 
        //                 id_meeting: plainMeetingObject['id'],
        //                 id_person: invited['id'],
        //                 mail: invited['mail'],
        //                 mandatory: invited['mandatory'],
        //                 status: invited['status']
        //                 },
        //         ])
        //         .eq('id_meeting', plainMeetingObject.id)
        //         .eq('id_person', invited.id)

        //     if (error) {
        //         console.log('error dans meeting create participant', { error })
        //         throw { error };
        //     }
        // })
}

export const DBdeleteMeeting = async (meetingId: string) => {
    const { data, error } = await supabase
        .from('meetings')
        .delete()
        .eq('id', meetingId)

    if (error) { throw { error }; }

    const { data: data2, error:error2 } = await supabase
        .from('meeting_person')
        .delete()
        .eq('id_meeting', meetingId)

    if (error2) { throw { error2 }; }
}

export const DBdeleteParticipant = async ( plainMeetingObject : { meetingId: string, personId: string } ) => {
    const { data: data2, error:error2 } = await supabase
        .from('meeting_person')
        .delete()
        .eq('id_meeting', plainMeetingObject.meetingId)
        .eq('id_person', plainMeetingObject.personId)

    if (error2) { throw { error2 }; }
}

export const DBaddParticipant = async ( plainMeetingObject : { meetingId: string, invited: InvitedMeetingType } ) => {
    const { data, error} = await supabase
        .from('meeting_person')
        .insert(
            { 
                id_meeting: plainMeetingObject.meetingId,
                id_person: plainMeetingObject.invited['id_person'],
                added_by: plainMeetingObject.invited['added_by'],
                duration: plainMeetingObject.invited['duration'],
                mail: plainMeetingObject.invited['mail'],
                mandatory: plainMeetingObject.invited['mandatory'],
                status: plainMeetingObject.invited['status']
                },
        )

    if (error) { throw { error }; }
}

export const DBaddlistParticipant = async ( meetingId: string, list_of_invited: InvitedMeetingType[] ) => {
    await Promise.all(list_of_invited.map( invited => {
        DBaddParticipant({meetingId, invited})
    }))
}

export const DBupdateParticipant = async ( plainMeetingObject : { meetingId: string, invited: InvitedMeetingType } ) => {
    console.log("DBupdateParticipant")
    const { data, error} = await supabase
        .from('meeting_person')
        .update(
            { 
                id_meeting: plainMeetingObject.invited['id_meeting'], 
                id_person: plainMeetingObject.invited['id_person'],
                added_by: plainMeetingObject.invited['added_by'],
                mail: plainMeetingObject.invited['mail'],
                mandatory: plainMeetingObject.invited['mandatory'],
                status: plainMeetingObject.invited['status'],
                duration: plainMeetingObject.invited['duration']
                },
        )
        .eq('id_meeting', plainMeetingObject.invited['id_meeting'])
        .eq('id_person', plainMeetingObject.invited['id_person'])

    if (error) { throw { error }; }

    // console.log("on y passe ici , DBupdateParticipant", plainMeetingObject, data )

    return plainMeetingObject
}


export const DBisExistingMeeting = async (meetingId: string)  => {
    console.log('DBisExistingMeeting')
    const { data, error } = await supabase
        .from('meetings')
        .select()
        .eq('id', meetingId)
        .returns<MeetingType[]>();
    if (error) { throw { error }; }
    return data.length > 0 as boolean
}

export const DBrequestExistingMeeting = async (meetingId: string) => {
    console.log('DBrequestExistingMeeting')
    const { data, error } = await supabase
        .from('meetings')
        .select()
        .eq('id', meetingId)
        .returns<MeetingType[]>();

    if (error) { throw { error }; }

    if (data.length > 1) { console.log("Il y a un probleme avec deux réunion avec cet id !!") }

    if (data.length === 0) { 
        console.log("Faire tourner DBisExistingMeeting avant")
        throw new Error("On n'a pas de meeting enregistré avec cet id") 
    }
    return data[0]
}

export const DBrequestMeetingParticipants = async (meetingId: string) => {
    console.log('DBrequestMeetingParticipants')
    const { data, error } = await supabase
        .from('meeting_person')
        .select()
        .eq('id_meeting', meetingId)
        .returns<InvitedMeetingType[]>();
    if (error) { throw { error }; }

    const participants = await data
    return participants
}

export const DBrequestMyMeeting = async (meetingId: string, personId: string) => {
    const { data, error } = await supabase
        .from('meeting_person')
        .select()
        .eq('id_meeting', meetingId)
        .eq('id_person', personId)
        .returns<InvitedMeetingType[]>();
    if (error) { throw { error }; }

    const participants = await data
    return participants
}



export const DBrequestMeeting = async (meetingId: string) => {
    DBisExistingMeeting(meetingId).then(
        isExisting => {
            if (isExisting) {
                DBrequestExistingMeeting(meetingId).then(
                    meetingData => {
                        DBrequestMeetingParticipants(meetingId).then( 
                            participants => {
                                meetingData['participants'] = participants
                                return meetingData
                            }
                        )
                    }
                )
            } else {
                return null
            }
            return null
        }
    )
}

export const DBrequestUserMeetings = async (range: any) => {
    const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
    if (errorAuth){
        console.log('Probleme dans getUser', errorAuth)
    }
    const userId = dataAuth.user?.id
    if (userId){
        const { data: dataMeeting, error:errorMeeting } = await supabase
            .from('meetings')
            .select()
            .eq('user_id', userId)
            .returns<MeetingType>();

        if (errorMeeting) { throw { errorMeeting }; }

        const data = dataMeeting
        return data
    }
}

