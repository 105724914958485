import React from 'react'
import { useSelector } from 'react-redux'
import { EventInput } from '@fullcalendar/core'

import { Col, Container, Row } from 'react-bootstrap';

import { RealPropositionsSelector, WeekendSelector, PossiblePropositionsSelector } from '../../store/selectors'



const TextProposition = () => {  

    const weekendsVisible = useSelector(WeekendSelector)
    const propositions = useSelector(RealPropositionsSelector)
    const possiblePropositions = useSelector(PossiblePropositionsSelector)
  
    const format_time = (time : Date) => {
        return time.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }

    const row_event = (event:EventInput) => {
    //     if self.start.date() == self.end.date():
    //     return "le {} de {} à {} with {}={}".format(self.start.strftime('%a %d %b'), self.start.strftime('%H:%M'), self.end.strftime('%H:%M'), self.value_name, self.value)
    // else:
    //     return "du {} au {} with {}={}".format(self.start.strftime('%a %d %b %HH%M'), self.end.strftime('%a %d %b %HH%M'), self.value_name, self.value)

        let start = event.start ? new Date(event.start.toLocaleString()) : new Date()
        let end = event.end ? new Date(event.end.toLocaleString()) : new Date()
        
        if (start.getDate() == end.getDate()) {
            return <Row key={start.toLocaleString() + end.toLocaleString()}> - le {start.toLocaleDateString()} entre {format_time(start)} et {format_time(end)} </Row>
        }
        return <Row style={{display:'flex', justifyContent:'left'}} key={start?.toLocaleString() + end.toLocaleString()}> - entre le {start?.toLocaleDateString()} à {start.toLocaleTimeString()} et le {end.toLocaleDateString()}  à {end.toLocaleTimeString()} </Row>
    }

    return (
        <>
            <Container>
                <Col>
                    <Row>
                        {propositions.map(row_event)}
                    </Row>
                    <br />
                    <Row>
                    <p style={{
                            textAlign:"left"
                        }}> Si aucun de ces créneaux, ayant ma préférence pouvait convenir, la réunion pourrait également être fixées sur les plages suivantes :
                    </p>
                    </Row>
                    <Row>
                    {possiblePropositions.map(row_event)} 
                    </Row>
                </Col>
            </Container>
            
        </>
    )
}


export default TextProposition