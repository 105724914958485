import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToggleButton, Button, ButtonGroup, Col, Container, Modal, Row } from 'react-bootstrap';

import { useSelector } from 'react-redux'

import { PossiblePropositionsSelector, RealPropositionsSelector, RejectedPropositionsSelector } from '../../store/selectors';

import { DBcreateProposition, DBrequestPropositionsOfMeeting, adapt_avalability, initialize_to_accepted } from '../../database/PropositionsActions';
import { postIsFree } from '../../api/postIsFree';
import { convert_date_to_utc_without_second } from '../../database/convert_to_db';


export const ButtonSendProposition = () => {

    let navigate = useNavigate();
    const { id_meeting, id_person } = useParams();
    const realPropositions = useSelector(RealPropositionsSelector)
    const possiblePropositions = useSelector(PossiblePropositionsSelector)
    const rejectedPropositions = useSelector(RejectedPropositionsSelector)

    const send_proposition = async () => {
        await Promise.all([
            realPropositions.map(item => { 
                DBcreateProposition(item)} ) ,
            possiblePropositions.map(item => { 
                console.log(item)
                DBcreateProposition(item)} ) ,
            rejectedPropositions.map(item => { 
                console.log(item)
                DBcreateProposition(item)} )
        ])
    }

    return (
        <Button onClick={() => {
            send_proposition().then(
                () => { 
                    if (id_meeting) {
                        if (id_person) {
                            let new_meetings = realPropositions.filter(item => { return item['submitted_to'].includes(id_person) && item['submitted_to'].length === 1 } )
                            let slots_to_send = new_meetings.map(item => { return {
                                start: convert_date_to_utc_without_second(item['start']),
                                end: convert_date_to_utc_without_second(item['end'])
                            }})

                            postIsFree(id_meeting, slots_to_send).then(
                                (results_json ) => {
                                    console.log("le resultat de l'appel à isFree est", results_json)
                                    if (results_json.every(true)) {
                                        alert('Bonne nouvelle, en consultant les informations des autres participants détenus par DaTime, les créneaux que vous avez proposés semble possible !')
                                    } else {
                                        alert("Avec les informations à sa disposition, DaTime peut malheureusement déjà dire que certains créneaux ne sont pas possibles pour d'autres DBrequestMeetingParticipants. Vous voulez en proposer d'autres ?")
                                    }
                                }
                            )
                        }
                        alert('Vous êtes sûr ? les participants vont être immédiatement prévenu')
                        // if (id_meeting) {postChronopt(id_meeting, 'mail')}
                    }
                    alert('Voulez-vous marquer ces créneaux comme provisoire dans votre agenda ?')
                }
            )
            }}> Proposer ces créneaux
        </Button>
    )
}