import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


import { MeetingSelector, UserSelector } from '../store/selectors';
import { MeetingActions } from '../store/MeetingReducer';
import { DBcreateMeeting, DBaddParticipant, DBdeleteParticipant, DBupdateParticipant, DBrequestMeeting, DBaddlistParticipant } from '../database/MeetingAction';


export const RedirectMeetingWithoutIdInUrl = () => {
    // const events = useSelector(EventsSelector)
    let navigate = useNavigate();
    const { id_meeting_url } = useParams();


    const localMeetingPage = useSelector(MeetingSelector)

    useEffect( () => {
        navigate('/meeting/' + localMeetingPage['id'])
    }, [localMeetingPage])

    console.log('redirect in action')

    return (null)
}