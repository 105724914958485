import { helperPostChronopt } from './post_chronopt_helper'

type stepChronopt = 'init' | 'next'


export const postChronopt = async (meeting_id: string, step: stepChronopt) => {

    const json = await helperPostChronopt( {
         url_slash : '/chronopt',
         json: JSON.stringify({meeting_id: meeting_id, step: step}) } 
        )

    return json
} 