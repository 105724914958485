import { hashById } from './utils'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { supabase } from '../components/auth/supabaseClient';
import type { TypeProposition } from '../types/Propositions';

const stateSlice = createSlice({
    name: "propositionSlice",
    initialState: [] as TypeProposition[],
    reducers: {
        setPropositions: (propositions, action: {payload: TypeProposition[]}) => {
            return action.payload
        },
        addProposition: (propositions, action: {payload: TypeProposition}) => {
            return [...propositions, action.payload]
        },
        updateProposition: (propositions, action: {payload: TypeProposition}) => {
            let other_propositions = propositions.filter(item => item.id !== action.payload.id)
            return [...other_propositions, action.payload]
        },
        deleteProposition: (propositions, action: {payload: string }) => {
            return propositions.filter(item => item.id !== action.payload)
        },
        flushPropositions: (propositions) => {
            return []
        }
    },
});

export const { actions: PropositionsActions, reducer: PropositionsReducer } = stateSlice 

export const { setPropositions, addProposition, updateProposition, deleteProposition, flushPropositions } = PropositionsActions
