import React from 'react'
import { useNavigate } from 'react-router-dom';
import {BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';

import GlobalStyle from './theme/globalStyle';
import Navbar from './components/Navbar'

// import AccueilInvitation from './pages/accueilInvitation'
import Accueil from './pages/accueil'
// import Answer_invit from './pages/myMeeting';
import Organize_simple from './pages/meeting';
import Decision from './pages/slot_decision';
import InputDispo from './pages/availabilities';
import ReSchedule from './pages/rescheduling';
import { ProfileParameters } from './pages/profile';
import { FAQ_Page } from './pages/faq';
import ErrorPage from './pages/404'
import NavbarInvited from './components/NavbarInvited';
import DispoText from './pages/invitation';
import { RedirectMeetingWithoutIdInUrl } from './pages/organise_redirect';
import { Container } from 'react-bootstrap';
import { ResetPasswordPage } from './pages/reset_password';
import { TestAPIPage } from './pages/9138test';

const copyright = String.fromCodePoint(0x00A9);

class AppPro extends React.Component {
	
  render() {
    return (
		<Container style={{width:'100%', margin:'0rem', boxSizing:'content-box'}}>
			<GlobalStyle/>
        	<Router>
				<Routes>
					<Route element={ <Container> <Navbar/> <Outlet></Outlet> </Container> }>
						<Route path='*' element={ <ErrorPage/> } />
						<Route path='/' element={ <Accueil/> } />
						<Route path='/datime' element={ <Accueil/> } />

						<Route path='/meeting' element={ <RedirectMeetingWithoutIdInUrl /> } />
						<Route path='/meeting/:id_meeting'  element={ <Organize_simple /> } />

						<Route path='/slot_decision' element={ <Decision /> }>
							<Route path=':id_meeting' />
							{/* <Route path=':id_meeting/:id_invited' /> */}
						</Route>
						
						<Route path='/availabilities/:id_meeting' element={ <InputDispo /> }>
							{/* <Route path=':id_meeting' /> */}
							{/* <Route path=':id_invited/:id_meeting' /> */}
						</Route>

						<Route path='/rescheduling' element={ <ReSchedule/> }/>
						<Route path='/faq' element={ <FAQ_Page/> } />

						<Route path='/profile' element={ <ProfileParameters/> }/>
						<Route path='/new_password' element={ <ResetPasswordPage/> }/>

					</Route>
					<Route element={ <div> <NavbarInvited/> <Outlet></Outlet> </div> }>

						<Route path='/invitation' element={ <DispoText /> }/>
						<Route path='/invitation/:id_meeting/:id_person' element={ <DispoText/> }/>
						
					</Route>

					<Route path='/test' element={ 
						<TestAPIPage/> 
						}>
					</Route>
				</Routes>
			</Router>
      	</Container>
    )
  }
  
}

export default AppPro
