
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToggleButton, Button, ButtonGroup, Col, Container, Modal, Row } from 'react-bootstrap';


import CalendarProposition from '../modules/calendarPropositions';
import { useDispatch, useSelector } from 'react-redux'

import { UserIdSelector, UserSelector } from '../store/selectors';
import TextProposition from '../views/invitation/TextPropositions';
import type {AppDispatch} from '../store/store'
import { addProposition, flushPropositions } from '../store/PropositionsReducer'
import { DBcreateProposition, DBrequestPropositionsOfMeeting, adapt_avalability, initialize_to_accepted } from '../database/PropositionsActions';
import { TypeProposition } from '../types/Propositions';
import { ButtonSendProposition } from '../views/invitation/SendPropositionsButton';
// import { GetCreneau } from '../components/getCreneau';

const PropositionText = () => {
    // pour l'instant, on affiche juste le texte
    // pour en faire une disponibilité on met dans une base à côté

    let navigate = useNavigate();
    const { id_meeting, id_person } = useParams();
    const dispatch = useDispatch<AppDispatch>()

    const user_id = useSelector(UserIdSelector)
    const [modalOn, setModalOn] = useState(false);
    const [radioValue, setRadioValue] = useState('1');
    const handleClose = () => setModalOn(false);
    const connectedUser = useSelector(UserSelector)

    useEffect( () => {
        if (id_meeting) {
            DBrequestPropositionsOfMeeting(id_meeting).then(
                (data : TypeProposition[] ) => { 
                    data.map(item => { 
                        console.log('resquested proposition', item)
                        if (id_person) {
                            if (item['rejected_by'].length === 0) {
                                dispatch(addProposition(initialize_to_accepted(adapt_avalability(item, id_person), id_person)))
                            }
                        }
                    })
                }
            )
        }
    }, [id_meeting, id_person])
    
    const radios = [
        { name: 'Supprimer', value: '1' },
        { name: 'Déplacer si nécessaire', value: '2' },
        { name: 'Indéplaçable', value: '3' },
    ];


    return (
        <>
            <br /> 
            <Container>
                <Row>
                    <h2>Entrez vos disponibilités </h2> <br />
                    <h3>pour prendre le rendez-vous qui vous convient </h3>
                    <p style={{ backgroundColor: 'rgba(240, 50, 90, 0.5)',}}>Toutes les informations que vous entrez ici ne sont connues que de vous
                    et ne sont pas partagées </p>
                </Row>
                <hr />
                <Row>                        
                     <h3>Cliquer une fois pour dire que le créneau n'a pas votre préférence, deux fois pour dire qu'il ne vous convient pas </h3>
                <hr />
                </Row>
                <Row>
                    <Col className='align-items-center'  sm={8}>
                        <CalendarProposition removeOnClick={radioValue === '0'}></CalendarProposition>
                    </Col>
                    <Col>
                        <Row className='align-items-center' >
                            <br /> <br /> <br /> <br />
                            <ButtonSendProposition />
                            <br /> <br />
                            <hr />
                            <Button onClick={() => { dispatch(flushPropositions()) }}> 
                                Réinitialiser
                            </Button>
                        </Row>
                        <Row> <h1/> <h1/> <hr/> </Row>
                        {/* <Row> <Weekend/> </Row>
                        <Row> <Lunch/> </Row> */}
                        <Row style={{ display: 'flex', backgroundColor: 'grey', alignItems: 'center', justifyContent: 'center' }}>
                            <h4 >ou copier-coller dans un mail ou valider directement ci-dessous</h4>
                            <TextProposition/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PropositionText
