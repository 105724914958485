import { createSlice } from '@reduxjs/toolkit'
import { InvitedMeetingType, InvitedType } from '../types/Dater';


import { createId } from '../api/createId';
const default_type_RDV = {
    value: 10,
    label: 'type_de_rdv',
    times: [{start: "09:30", end: "12:30", tz: "UTC"}] // non testé car avant, il y avait les secondes [{start: "09:30:00", end: "12:30:00", tz: "UTC"}] 
}
const unset_invited = { 
    id: createId(),
    id_person: createId(),
    id_meeting: createId(),
    added_by: '',
    created_by: null,
    type: default_type_RDV,
    status: "to_invite",
    duration: 60,
    mandatory: true,
    title:'unset_meeting',
    nb_asked: 0
} as InvitedMeetingType


const InvitedSlice = createSlice({
    name: "invitedSlice",
    initialState: unset_invited as InvitedMeetingType,
    reducers: {
        createInvited: (invited, action) => {
            return action.payload
        },
        
        deleteInvited: (invited, action) => {
            return unset_invited
        },
    }
});


export const { actions: InvitedActions, reducer: InvitedReducer } = InvitedSlice 

export const { createInvited, deleteInvited } = InvitedActions

export default InvitedSlice.reducer