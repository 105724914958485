
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToggleButton, Button, ButtonGroup, Col, Container, Modal, Row, } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'


import CalendarProposition from '../modules/calendarPropositions';
import SelectRoutine from '../components/selectRoutine';
import { CalendarScreenCapture } from '../modules/ScreenCaptureAgenda';
import { GetCreneau } from '../components/getCreneau';

import { EventsSelector, PossiblePropositionsSelector, RealPropositionsSelector, RejectedPropositionsSelector, UserIdSelector, UserSelector } from '../store/selectors';
import { DBcreateEvent } from '../database/EventsAction';
import type {AppDispatch} from '../store/store'
import { addProposition, deleteProposition, flushPropositions } from '../store/PropositionsReducer'
import { from_Proposition_to_Event } from '../format/change_format';
import { addEvent } from '../store/EventsReducer';
import { DBcreateProposition, DBrequestPropositionsOfMeeting, adapt_avalability } from '../database/PropositionsActions';
import { TypeProposition } from '../types/Propositions';
import { postChronopt } from '../api/postChronopt'
import { DBisExistingMeeting, DBrequestExistingMeeting, DBrequestMeetingParticipants } from '../database/MeetingAction';
import { MeetingType } from '../types/Meeting';
import { MeetingActions } from '../store/MeetingReducer';
import { ButtonSearchCreneau } from '../views/availabilities/SearchCreneauButton';


const InputProposition = () => {

    let navigate = useNavigate();
    const { id_meeting } = useParams();

    const dispatch = useDispatch<AppDispatch>()

    const user_id = useSelector(UserIdSelector)
    const [modalOn, setModalOn] = useState(false);
    const [radioValue, setRadioValue] = useState('0');
    const handleClose = () => setModalOn(false);
    const realPropositions = useSelector(RealPropositionsSelector)
    const possiblePropositions = useSelector(PossiblePropositionsSelector)
    const rejectedPropositions = useSelector(RejectedPropositionsSelector)

    useEffect( () => {
        if (id_meeting) {

            DBisExistingMeeting(id_meeting).then(
                isExisting => {
                    if (isExisting) {
                        DBrequestExistingMeeting(id_meeting).then(
                            data => {
                                if (data !== null) {
                                    const to_use = data as MeetingType
                                    DBrequestMeetingParticipants(id_meeting).then( 
                                        participants => {
                                            to_use['participants'] = participants
                                            dispatch(MeetingActions.createMeeting(to_use))
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            )

            DBrequestPropositionsOfMeeting(id_meeting).then(
                (data : TypeProposition[] ) => { 
                    data.map(item => { 
                        console.log('resquested proposition', item)
                        if (user_id) {
                            dispatch(addProposition(adapt_avalability(item, user_id)))
                        }
                    })
                }
            )
        }
    }, [id_meeting, user_id])

    const radios = [
        { name: 'Supprimer', value: '1' },
        { name: 'Déplacer si nécessaire', value: '2' },
        { name: 'Indéplaçable', value: '3' },
    ];

    const send_proposition = async () => {
        await Promise.all([
            realPropositions.map(item => { 
                DBcreateProposition(item)} ) ,
            possiblePropositions.map(item => { 
                console.log(item)
                DBcreateProposition(item)} ) ,
            rejectedPropositions.map(item => { 
                console.log(item)
                DBcreateProposition(item)} )
        ])
    }

    return (
        <>
            <br /> 
            <Container>
                <Modal
                    backdrop="static"
                    keyboard={true}
                    show={modalOn}
                    onHide={handleClose}
                    size="xl"
                    >
                    <Modal.Header closeButton>
                        <h2>  Faites une capture et contrôler le résultat </h2> 
                    </Modal.Header>
                    <Modal.Body>
                        <CalendarScreenCapture></CalendarScreenCapture>
                    </Modal.Body>
                </Modal>
                <Row>
                    <h2>Entrez vos disponibilités </h2> <br />
                    <h3>pour prendre le rendez-vous qui vous convient </h3>
                    <p style={{ backgroundColor: 'rgba(240, 50, 90, 0.5)',}}>Toutes les informations que vous entrez ici ne sont connues que de vous
                    et ne sont pas partagées </p>
                </Row>
                <Row> Pour définir un plage d'indisponibilité, cliquer pour créer un créneau et cliquer deux fois dessus</Row>
                {/* <Row style={{width: '100%', justifyContent: 'center', display: "flex"}}>Mode</Row>
                <Row className="justify-content-md-center">
                    <Col xs lg="2" style={{width: '30%', justifyContent: 'right', display: "flex"}}>
                        Agenda <br/>
                        J'ajoute un évenement pour dire quand je ne suis **pas** disponible
                    </Col>
                    <Col md="auto">
                        <Form className='form-switch-xxl' >
                            <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    className='form-switch-xl'
            
                                />
                        </Form>                         
                        </Col>
                    <Col xs lg="2" style={{width: '30%', justifyContent: 'left', display: "flex"}}>
                        Disponibilité <br/>
                        J'ajoute un évenement pour dire quand je suis disponible
                    </Col>
                </Row> */}
                <Row>
                    <Col className='align-items-center'  sm={8}>
                        <CalendarProposition removeOnClick={radioValue === '1'}></CalendarProposition>
                        <br /> 
                        <Row>
                            <br /> 
                            {/* <ButtonSearchCreneau /> */}
                        </Row>
                    </Col>
                    <Col className='align-items-center'>
                        <Row className='align-items-center' >
                            <br /><br />
                            <br /><br />
                            <Button 
                                disabled={realPropositions.length + possiblePropositions.length  === 0 }
                                onClick={() => {
                                    send_proposition().then(
                                        () => { if (id_meeting) {postChronopt(id_meeting, 'next')} }
                                    )
                            }}> Proposer ces créneaux</Button>
                            <br /><br />
                            <br /><br />
                            <hr />
                            <Button 
                                disabled={rejectedPropositions.length === 0}
                                onClick={() => {
                                    rejectedPropositions.map(proposition => {
                                        if (user_id){
                                            let new_event = from_Proposition_to_Event(proposition, user_id)
                                            DBcreateEvent(new_event)
                                            dispatch(addEvent(new_event))
                                            dispatch(deleteProposition(proposition.id))
                                        }
                                    })

                            }}> Retenir les créneaux rejetés pour les prochains rendez-vous </Button>
                            <br /><br />
                            <hr />
                            <Button onClick={() => { 
                                realPropositions.map(item => {dispatch(deleteProposition(item.id))})
                                possiblePropositions.map(item => {dispatch(deleteProposition(item.id))})
                                // dispatch(flushPropositions())
                            }}> Réinitialiser </Button>
                            <br /><br />
                            <hr />
                            <Button onClick={() => { 
                                rejectedPropositions.map(item => {dispatch(deleteProposition(item.id))})
                                // dispatch(flushPropositions())
                            }}> Réinitialiser les créneaux rejetés</Button>
                                                        <br /><br />
                            <hr />
                            <Button onClick={() => { 
                                navigate('/meeting/' + id_meeting)
                                // dispatch(flushPropositions())
                            }}> Revenir à l'organisation de la réunion</Button>

                        </Row>
                        <Row> <h1/> <h1/> </Row>
                        { user_id ? <Row> <br /> <GetCreneau/> </Row> : null }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default InputProposition