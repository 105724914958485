import { Link } from 'react-router-dom'

import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export const FAQ_Page = () => {

    return (
        <Container>
            <br />
            <h3>J'ai des difficultés à répondre à une invitation </h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                <Accordion.Header>Je n'arrive pas à synchroniser mon compte outlook</Accordion.Header>
                <Accordion.Body>
                    Cela est en général dû à des restriction de votre administrateur.
                    Si vous le souhaitez, vous pouvez nous transmettre ses coordonnées dans l'onglet <Link to="/contact_pb_outlook">contact</Link> et nous le contacterons 
                </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                <Accordion.Header>Je n'arrive pas à synchroniser mon compte Google</Accordion.Header>
                <Accordion.Body>
                    En général, les autorisations sont données par défaut pour les comptes google, si ce n'est pas le cas,
                    vous pouvez nous transmettre dans l'onglet <Link to="/contact_pb_google">contact</Link> ses coordonnées et nous le contacterons.
                </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <h3>Je suis administrateur</h3>
        </Container>
    );
}

