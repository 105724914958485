import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'


import { AppDispatch } from "../../store/store";
import { DBcreateDater, DBdeleteDater, DBupdateDater } from "../../store/DatersReducer";
import SupabaseAuth from '../../components/auth/SupabaseAuth';

type PropsModalConnectedType = {
    modalOn: boolean,
    handleClose:  () => void
}

export const ModalConnected = ( {modalOn = false, handleClose }: PropsModalConnectedType) => {

    const dispatch = useDispatch<AppDispatch>()
    

    return (

        <Modal
        show={modalOn}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
             <Modal.Header closeButton>
                 <Modal.Title> { "Connectez-vous pour organiser une réunion"} </Modal.Title>
             </Modal.Header>
   
            <Modal.Body>
                    <SupabaseAuth/>
            </Modal.Body>
        </Modal>
    );
}