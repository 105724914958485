import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    EventApi,
    DatesSetArg,
    DateSelectArg,
    EventAddArg,
    EventChangeArg, 
    EventClickArg,
    EventContentArg,
    EventHoveringArg,
    EventMountArg,
} from '@fullcalendar/core'
// import { EventImpl } from '@fullcalendar/core/internal'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
// import rrulePlugin from '@fullcalendar/rrule';
import iCalendarPlugin from '@fullcalendar/icalendar';
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import {  InfoCircle } from 'react-bootstrap-icons';

import DraggApp from '../components/drag-items'
import Weekend from '../components/weekend'

import { createId } from '../api/createId'

import { RealPropositionsSelector, PossiblePropositionsSelector, RejectedPropositionsSelector, PropositionsSelector,
    WeekendSelector, UserIdSelector, EventsFCSelector, BusinesHoursSelector, 
    MinTimeSelector, MaxTimeSelector } from '../store/selectors'
import { TypeProposition } from '../types/Propositions'

import type {AppDispatch} from '../store/store'
import { addProposition, deleteProposition, updateProposition, setPropositions } from '../store/PropositionsReducer'
import { useParams } from 'react-router-dom'
import { from_EventApi_to_Proposition, from_date_to_Proposition } from '../format/change_format'
import { Button, Overlay, OverlayTrigger } from 'react-bootstrap'
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
const LOCALES = [ esLocale, frLocale ];

let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
type CalendarPropositionPropsType = {
    removeOnClick: boolean
}

const CalendarProposition = (props : CalendarPropositionPropsType) => {  

    const weekendsVisible = useSelector(WeekendSelector)
    const events = useSelector(EventsFCSelector)
    const realPropositions = useSelector(RealPropositionsSelector)
    const possiblePropositions = useSelector(PossiblePropositionsSelector)
    const rejectedPropositions = useSelector(RejectedPropositionsSelector)
    const bh = useSelector(BusinesHoursSelector)

    const slotMinTime = useSelector(MinTimeSelector)
    const slotMaxTime = useSelector(MaxTimeSelector)
    // console.log('real proposition', realPropositions)
    // console.log('possible proposition', possiblePropositions)
    // console.log('rejected proposition', rejectedPropositions)

    const dispatch = useDispatch<AppDispatch>()
    // les actions que l'on va utiliser ici pour d'abord changer la database puis pour changer le state

    const user_id_connected = useSelector(UserIdSelector)
    const [user_id, setUser_id] = useState<string>();
    const default_location = null // useSelector(DefaultLocationSelector)
    const default_rappel = null // useSelector(DefaultRappelSelector)
    const default_transport = null // useSelector(DefaultTransportSelector)
    const { id_meeting, id_person} = useParams();
    
    useEffect( () => {
        if (user_id_connected) { setUser_id(user_id_connected)
        } else {
            if (id_person) { setUser_id(id_person)
            } else {
                setUser_id(undefined)
            }
        }
    }, [id_person, user_id_connected])
   
    const handleDates = (rangeInfo: DatesSetArg) => {} // pas d'appel à la base

    const handlePropositionAdd = (addInfo: EventAddArg) => {
        if (user_id){
            const temp_proposition = from_EventApi_to_Proposition(addInfo.event, user_id)
            dispatch(addProposition(temp_proposition))
        }
    }

    const handlePropositionChange = (changeInfo: EventChangeArg) => {
        if (user_id) {
            let temp_proposition = from_EventApi_to_Proposition(changeInfo.event, user_id)
            dispatch(updateProposition(temp_proposition))
        }
    }
    
    const handlePropositionClick = (clickInfo: EventClickArg) => {
        const change_availabiliy = (availability:number) => {
            let result = availability >= 0.5 ?  availability - 0.5 : 1
            return result
        }

        const change_user_opinion = (proposition:TypeProposition, user_id:string) : [string[], string[], string[]] => {
            if (proposition['validated_by'].includes(user_id)){
                const possible_by = [...proposition['possible_by'], user_id]
                console.log('possible_by', possible_by)
                return ([proposition['validated_by'].filter(item => item !== user_id), possible_by, proposition['rejected_by']])
            } else if (proposition['possible_by'].includes(user_id)){
                const rejected_by = [...proposition['rejected_by'], user_id]
                return ([proposition['validated_by'], proposition['possible_by'].filter(item => item !== user_id), rejected_by])
            } else if (proposition['rejected_by'].includes(user_id)){
                const validated_by = [...proposition['validated_by'], user_id]
                return ([validated_by, proposition['possible_by'], proposition['rejected_by'].filter(item => item !== user_id)])
            } else { 
                return [[], [], []]}
        }

        if (props.removeOnClick) {
            if (user_id){
                let clickedInfo = from_EventApi_to_Proposition(clickInfo.event, user_id)
                dispatch(deleteProposition(clickInfo.event.id))
            }
        } else {
            if (user_id){
                let proposition = from_EventApi_to_Proposition(clickInfo.event, user_id)
                let new_availability = change_availabiliy(proposition.availability)
                let new_title = new_availability == 1 ?  'Proposition possible' : new_availability == 0 ? "occupé" : "Possible mais pas parfait"
                let [new_ok, new_possible, new_rejected] = change_user_opinion(proposition, user_id)
                let new_proposition = {...proposition, 
                                availability: new_availability,
                                title: new_title,
                                validated_by: new_ok,
                                possible_by: new_possible,
                                rejected_by: new_rejected
                            }
                console.log('new_proposition', new_proposition)
                dispatch(updateProposition(new_proposition))
            }
        }
    }
    
    const renderPropositionContent = (eventContent: EventContentArg) => {
        return (
            <Container>
                    <Row>
                        <Col sm={10}>
                            <b>{eventContent.timeText}</b>
                        </Col>
                        <Col sm={2}>
                            <OverlayTrigger overlay={
                                    <Tooltip id={'tooltip-' + eventContent.event.id}>
                                        {<p> Cliquer pour changer la couleur : 
                                            <ul style={{textAlign:"left"}}> 
                                                <li> vert : ok </li>
                                                <li> orange : possible mais pas souhaité </li>
                                                <li> rouge : impossible </li>
                                            </ul>
                                        </p>}
                                    </Tooltip>
                                }>
                                <InfoCircle></InfoCircle>
                            </OverlayTrigger>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <i>{eventContent.event.title}</i>
                        </Col>

                    </Row>
                </Container>
        )
    }

    const handleDateSelect = (selectInfo: DateSelectArg) => {
        if (user_id && id_meeting){
            let event_to_add = from_date_to_Proposition(selectInfo, user_id, id_meeting)
            dispatch(addProposition(event_to_add))
            console.log('in adding', event_to_add)
        }
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect() // clear date selection
    }

    const handleEvents = (local_events: EventApi[]) => {
        // console.log('handleEventsd', local_events)
    }

    const dropEvents = () => {
        console.log('dropEvent')
    }

    const handleClose = () => null;

    return (

        <Container>
            <Row>
                <Col>
                    <FullCalendar
                        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, iCalendarPlugin]}
                        headerToolbar={{
                        // left: 'prev,next today',
                        // center: 'title',
                        // right: 'timeGridDay,timeGridWeek,dayGridMonth'
                        }}
                        businessHours= {bh}
                        slotMinTime = {slotMinTime}
                        slotMaxTime = {slotMaxTime}
                        contentHeight = {'auto'}   
                        titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
                        locale={"fr"}
                        locales = {LOCALES}
                        initialView='timeGridWeek'
                        editable={true}
                        droppable={true}
                        selectable={true}
                        allDaySlot={false}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={weekendsVisible}

                        eventSources = {[
                            // your event source
                            {
                                events: realPropositions,
                                color: 'green',     // an option!
                                textColor: 'black' // an option!
                            },
                            { events: rejectedPropositions, color: 'red' },
                            { events: possiblePropositions, color: 'orange' },
                            {
                                events: events,
                                color: 'red',     // an option!
                                textColor: 'black', // an option!
                                editable: false
                            }
                            // any other event sources...
                        ]}
                        datesSet={handleDates}
                        select={handleDateSelect}
                        eventAdd={handlePropositionAdd}
                        eventChange={handlePropositionChange} // called for drag-n-drop/resize
                        eventContent={renderPropositionContent} // custom render function
                        eventClick={handlePropositionClick}
                        eventDrop={dropEvents}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                    />
                </Col>
            </Row>
        </Container>
    )
}


export default CalendarProposition