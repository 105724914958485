import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

import emailjs from "@emailjs/browser";

import { DaterType } from "../types/Dater";
import { AppDispatch } from "../store/store";
import { DBcreateDater, DBdeleteDater, DBupdateDater } from "../store/DatersReducer";


type PropsSendEmailModal = {
    modalOn: boolean,
    toRemoveOn: boolean,
    handleClose:  () => void,
    data: {
        from: string,
        to_email: string,
        personnal_link: string,
        message?: ''
    }
}

export const SendEmailModal = ( 
    {modalOn = false, toRemoveOn = false, handleClose, 
        data}: PropsSendEmailModal) => {

    const dispatch = useDispatch<AppDispatch>()
    const [textObject, setTextObject] = useState("Invitation à une réunion");
    const [textBody, setTextBody] = useState(
        "Cette option n'est disponible qu'avec un forfait premium");
    
    useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_KEY as string,), []);
     
    const emailRef = useRef<HTMLInputElement>();
    const nameRef = useRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false);


    // TODO: using sendForm semble approprié
    const onSendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log("Envoi d'un email d'invitation de" + data['from'] + " à " + data['to_email'])
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_1_ID as string;
        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                name: nameRef?.current?.value,
                recipient: emailRef?.current?.value,
                to_email: data['to_email'],
                from_name: data['from'],
                to_name: 'comment definir ce nom',
                link: 'lemonde.fr',
                type_reunion: 'un dejeuner',
                reply_to: 'no_reply@datime.pro',
                personnal_link: data['personnal_link']
            });
            alert("email successfully sent check inbox");
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
        handleClose()
    } 

    return (
        <Modal
            show={modalOn}
            onHide={handleClose}
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title> { "Personnaliser le mail à " + data['to_email']} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form  onSubmit={onSendEmail}>
                    <Form.Group className="mb-3" controlId="addDater.Nom">
                    <Form.Label>Objet</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder={textObject}
                        onChange={e => { setTextObject(e.target.value) } }
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ddDater.Label">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea"
                        size={"lg"}
                        rows={10}
                        placeholder={textBody}
                        onChange={e => {setTextBody(e.target.value )} }
                    />
                    </Form.Group>
                    <Button variant="primary" type="submit"> Envoyer </Button> 
                </Form>
            </Modal.Body>
        </Modal>
    );
}