import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container } from "react-bootstrap";
import { supabase } from "../components/auth/supabaseClient";

export const ResetPasswordPage = () => {
    let navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [valid, setValidity] = useState(true);
    const [compare, setCompare] = useState(true);

    useEffect(() => {
        const regExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (newPassword) {
            setCompare(true);
            if (newPassword.match(regExp)) {
            setValidity(true);
            } else {
            setValidity(false);
            }
        }
    }, [newPassword]);

    function comparePassword() {
        if (newPassword !== confirmPassword) {
            setCompare(false);
        } else {
            setCompare(true);
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        comparePassword();
        const { data, error } = await supabase.auth.updateUser({password: newPassword})
        navigate('/')
    }

    return (
        <Container >
            <Col md={{ span: 4, offset: 4 }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group  controlId="password_new">
                    <Form.Label>New Password: </Form.Label>
                    <Form.Control
                        type="password"
                        value={newPassword}
                        required={true}
                        size="lg"
                        onChange={(e) => {
                        setNewPassword(e.target.value);
                        }}
                    />
                    {/* TODO: Show card with rules */}
                    {!valid ? (
                        <Form.Text  style={{ color: "red" }}>
                        Password should be minimum 8 characters in length and a mix of
                        uppercase, lowercase, digits and special characters.
                        </Form.Text>
                    ) : null}
                    </Form.Group>
                    <Form.Group  controlId="password_confirm">
                    <Form.Label>Confirm Password: </Form.Label>
                    <Form.Control
                        type="password"
                        value={confirmPassword}
                        required={true}
                        size="lg"
                        onFocus={() => setCompare(true)}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    </Form.Group>
                    {!compare ? (
                    <Form.Text className="text-muted">Passwords do not match</Form.Text>
                    ) : null}
                    <div className="d-grid gap-2" style={{margin:"10px"}}>
                        <Button size="lg" type="submit" disabled={!valid && !compare}>
                        Submit
                        </Button>
                    </div>

                </Form>
            
            </Col>
        </Container>
    );
}
