import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'

import { Button, ButtonGroup, Table, ProgressBar, Form, Container, Row, Col } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { InvitedMeetingType, InvitedType } from '../types/Dater';

import { ReactOsmGeocoding } from '../components/geocoder'
import { createId } from '../api/createId';

import { MeetingSelector, UserSelector } from '../store/selectors';
import { MeetingActions } from '../store/MeetingReducer';
import { DBcreateMeeting, DBaddParticipant, DBdeleteParticipant, DBupdateParticipant, DBisExistingMeeting, DBrequestExistingMeeting, DBaddlistParticipant, DBrequestMeeting, DBrequestMeetingParticipants } from '../database/MeetingAction';
import { AppDispatch } from '../store/store';
import { postChronopt } from '../api/postChronopt';
import { MeetingType } from '../types/Meeting';
import { ModalConnected } from '../views/meeting/ModalConnected';
import { MeetingForm } from '../views/meeting/MeetingForm';
import { TableParticipants } from '../views/meeting/TableParticipants';

const Organize = () => {
    // const events = useSelector(EventsSelector)
    let navigate = useNavigate();
    const { id_meeting } = useParams();
    const dispatch = useDispatch<AppDispatch>()

    const [modalAlertOn, setModalAlertOn] = useState(true);
    const [meetingCreated, setMeetingCreated] = useState(false)
    const [limitDate, setLimitDate] = useState<Date>(new Date());
    const [isNew, setIsNew] = useState(true);
    const connectedUser = useSelector(UserSelector)
    const localMeetingPage = useSelector(MeetingSelector)
    // la logique est la suivante : 
    // si selector dans l'url => on va chercher le meeting associé (on le met dans le store)
    // si pas de meeting dans l'url, on va chercher le store et on move vers l'url
    // si rien dans le store, on crée un nouveau meeting qu'on met dans le store et on navigue sur la page.
    // au final => on est toujours sur une page avec un id de meeting.
    // TODO : bien séparer le moment où l'on s'occupe d'avoir un id_meeting 
    // et le moment où on le crée, etc. 
    // Bref, revoir tout ce code qui est confus et probablement pas ce qu'il faut

    useEffect( () => {
        if (id_meeting) {
            DBisExistingMeeting(id_meeting).then(
                isExisting => {
                    if (isExisting) {
                        console.log('ce meeting a déjà été organisé')
                        setIsNew(false)
                        setMeetingCreated(true)
                        DBrequestExistingMeeting(id_meeting).then(
                            data => {
                                if (data !== null) {
                                    const to_use = data as MeetingType
                                    DBrequestMeetingParticipants(id_meeting).then( 
                                        participants => {
                                            to_use['participants'] = participants
                                            dispatch(MeetingActions.createMeeting(to_use))
                                            setMeetingCreated(true)
                                        }
                                    )
                                }
                            }
                        )
                    } else {
                        console.log('Nouveau meeting')
                        
                        dispatch(MeetingActions.deleteMeeting())
                        // dispatch(MeetingActions.createMeeting({...localMeetingPage, id:id_meeting}))
                        setMeetingCreated(false)
                    }
                }
            )
        } else {
            throw new Error("On doit avoir un meeting dans l'url")
        }
    }, [id_meeting])

    useEffect( () => {
        if (connectedUser) {
            setModalAlertOn(false)
            let already_in_invited = localMeetingPage['participants'].filter( (item) => { return(item.mail === connectedUser.email ) } )
            if (already_in_invited.length == 0) {
                const user_as_invited : InvitedMeetingType = {
                    'id': createId(),
                    'id_person': connectedUser ? connectedUser.id : '',
                    'id_meeting': id_meeting ? id_meeting : '',
                    'added_by': connectedUser ? connectedUser.id : '', 
                    'mail': connectedUser ? connectedUser.email : '' as string,
                    'status': 'to_invite',
                    'mandatory': true,
                    'duration': 0,
                    'title': 'nom de la réunion',
                    'nb_asked': 0,
                    'is_DaTimer': true,
                    }
                    setTimeout(() => {
                        dispatch(MeetingActions.updateParticipant({ meetingId: id_meeting, invited: user_as_invited }))
                      }, 2000);
                      
                // navigate('/meeting/' + user_as_invited['id_meeting'])
            }
        } else {
            setModalAlertOn(true)
        }
    }, [connectedUser, id_meeting, localMeetingPage['id']])

    const handleCloseAlert = () => {navigate("/")}

    const participant_to_send = (participant:InvitedMeetingType) => {
        // on update les participants avant d'envoyer avec les informations du meeting que l'on met par défaut
        return {
            ...participant,
            'duration': localMeetingPage['duration'],
            'title': localMeetingPage['object'],
        }
    }

    const onMeetingCreation = () => {
        setMeetingCreated(true)
        let newMeeting = {
            ...localMeetingPage, 
            "created_by": connectedUser?.id
        }
        if (localMeetingPage){
            DBcreateMeeting(newMeeting).then( () => {
                DBaddlistParticipant(localMeetingPage['id'], localMeetingPage['participants'].map(participant_to_send)).then(
                    () => {
                        postChronopt(localMeetingPage['id'], "init")
                    }
                )
                dispatch(MeetingActions.createMeeting(newMeeting))
                navigate("/availabilities/" + localMeetingPage['id'])
            })
        }
    }
   
    return (
        <>  
            <ModalConnected
                       modalOn={modalAlertOn} handleClose={handleCloseAlert}
                       />
            <h1>Organiser un rendez-vous en quelques clics</h1>
            <h3> Caractéristiques de la réunion </h3> 
            {/* { meetingPage.map( (item:MeetingType) => { return (<p> { item.id } </p>) } ) }  */}
            {/* <div style={{width: '100%', justifyContent: 'center', display: "flex"}}> <SimpleRdvTypeSelect/> </div>   */}
                <p>                        
                    Le nom, le type et la durée seront visibles par les invités et présent dans le mail qu'ils recevront.
                    Ensuite, tout ce que vous entrerez sera personnel 
                <br /><br /><br />
                </p>

            <Container>
                <Row>
                    <Col lg={5}>
                        <h3> Caractéristiques </h3>
                        <br />
                        <MeetingForm/>
                    </Col>
                    <Col>
                        <h3> Participants </h3>
                        <br />
                        <TableParticipants  id_meeting={id_meeting} connectedUser={connectedUser} isNew={isNew}/>
                    </Col>

                </Row>

                <Row>
                    
                    { connectedUser ? 
                        <>
                            <Row style={{justifyContent:"center"}}>
                                <Col sm={8}>
                                    <Row>
                                        <Col>
                                            <Button size="lg"
                                                onClick={ () => { 
                                                    onMeetingCreation()
                                                    alert("Merci on s'occupe de tout ! Vous pouvez fermer la page")
                                                }}
                                                disabled={ !isNew }>
                                                Créer le rendez-vous et <br /> laisser DaTime s'occuper de tout
                                                
                                            </Button>  
                                        </Col>
                                        <Col>
                                            <Button size="lg" onClick={ () => onMeetingCreation()} disabled={ !isNew }>
                                                Créer le rendez-vous et <br /> contrôler les créneaux qui seront proposés
                                            </Button>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                {/* <p> Vous pourrez modifier la liste des invités par la suite. <br />
                                En revanche, vous ne pourrez plus modifier l'objet, le type et la durée de la réunion par la suite. <br />
                                    Il faudra alors annuler la réunion et la recréer </p>  */}
                            </Row>
                        </> : 
                        <>
                            <h4>Veuillez-vous connecter pour créer une réunion  </h4>
                            <Button size="lg" disabled>
                                Créer la page de rendez-vous
                            </Button>
                        </>
                    }
                </Row>
            </Container>           

            { meetingCreated ? 
                    <>
                        <Button size="sm" variant="danger" onClick={ () => alert("Êtes-vous sûrs ? Vous poouvez dire oui (et que oui), cela va venir. Pour l'instant la solution c'est de recharger la page")}>
                            Annuler le rendez-vous
                        </Button>
                        <Button size="lg" onClick={ () => { 
                            if (connectedUser) {
                                navigate("/invitation/" + id_meeting + '/' + connectedUser.id)
                            }} 
                            }>
                            Remplir mes préférences et mes disponibilités
                        </Button>  
                    </>
 : null }

        </>
    )
}

export default Organize