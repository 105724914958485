import './AppAuth.css'
import React, { useState, useEffect } from 'react';

import { Auth } from '@supabase/auth-ui-react'
import *  as lang_fr  from './localization/fr.json' 
import {
    // Import predefined theme
    ThemeSupa,
} from '@supabase/auth-ui-shared'

import { supabase } from "./supabaseClient"
import { SessionActions, type Session } from "../../store/SessionReducer"
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { setSessionFromLocalSessionData, signIn } from '../../store/thunksAuth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { DBrequestDaters } from '../../store/DatersReducer';
import { setEvents, flushEvents } from '../../store/EventsReducer';
import { DBrequestEvents } from '../../database/EventsAction';
import { flushDater } from '../../store/DatersReducer';
import { DBrequestProfile, flushProfile } from '../../store/ProfileReducer';
import Stack from 'react-bootstrap/Stack';
import { useSearchParams } from 'react-router-dom';
import { UserIdSelector } from '../../store/selectors';
import { DBrequestLinkUser, ifFirstVisit } from './AuthAndIdLink';


export enum AuthCallTypes {
	SIGN_IN = "signInStatus",
	SIGN_UP = "signUpStatus",
	SIGN_OUT = "signOutStatus",
}


const SupabaseAuth = () => {

    const [localSession, setLocalSession] = useState<Session | null>(null)
    const [modalOn, setModalOn] = useState(false);
    const user_id = useSelector(UserIdSelector)
    
    const [searchParams, setSearchParams] = useSearchParams();
    const id_person = searchParams.get('id')
    const dispatch = useDispatch<AppDispatch>()

    const disconnect = async () => {
        const { error } = await supabase.auth.signOut()
        if (error){ throw error }
        setLocalSession(null)
    }

    // const anonConnect = async () => {
    //     const { data, error } = await supabase.auth.signInAnonymously({
    //         options: {
    //             captchaToken: process.env.REACT_APP_HCAPTCHA_SITE_KEY,
    //             data: {id: id_person}
    //         }
    //     })
    //     console.log('connection anonyme')
    // }

    useEffect( () => {
        if (id_person) {
            if (user_id) {
                if (id_person === user_id) {
                    console.log("Tout va bien on a bien l'id du compte et l'id connecté qui sont commun")
                } else {
                    console.log('Attention, le compte de connexion et celui invité ne correspondent pas')
                    disconnect()
                }
            } else {
                DBrequestLinkUser(id_person).then(
                    data => {
                        // data.length === 0 ? ifFirstVisit(id_person) : console.log("ce n'est pas la première fois qu'on se connecte avec cet id")
                    }
                )
            }
            // anonConnect()
        }
    }, [id_person, user_id])

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setLocalSession(session)
            if (session){
                dispatch(SessionActions.setLoggedInUserWithLocalData({ localSessionData: session }))
                DBrequestEvents(["20240401", "20240825"], session.user.id).then(
                    data => dispatch(setEvents(data))
                    )
                dispatch(DBrequestDaters())
            }
        })
    }, [])
    


    useEffect(() => {      
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setLocalSession(session)
            // console.log('Dans Supabase Auth, after onAuthStateChange', _event, session)
            if (_event == 'SIGNED_IN') {
                dispatch(SessionActions.signIn( { session: session, error:null}))
                if (session){
                    console.log('SIGNED IN id', session.user.id)
                    DBrequestEvents(["20240401", "20240825"], session.user.id).then(
                        data => { dispatch(setEvents(data)) }
                        )
                }
                dispatch(DBrequestDaters())
                dispatch(DBrequestProfile())
            }
            if (_event == 'SIGNED_OUT') {
                dispatch(SessionActions.signOut( { error: null } ))
                // en cas de log out, on ne joue pas sur la base mais seulement sur le state
                dispatch(flushEvents())
                dispatch(flushDater())
                dispatch(flushProfile())
            }
        })
  
        return () => subscription.unsubscribe()
        }, [])

    const handleClose = () => setModalOn(false);

    if (!localSession) {
        return (
            <>
                <Modal
                    backdrop="static"
                    keyboard={true}
                    show={modalOn}
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        Connexion
                    </Modal.Header>
                    <Modal.Body>
                        <Auth supabaseClient={supabase}  appearance={{ theme: ThemeSupa }} localization={{ variables: lang_fr }} providers={[]} /> 
                    </Modal.Body>
                </Modal>
                <Button onClick={ () => setModalOn(true)} variant="outline-info"> Se connecter </Button>
            </>
        )
    }
    else {
        return (
            <Container>
                <Stack gap={2} className="mx-auto">
                    { localSession.user.email } 
                    <Button variant='info' onClick={disconnect}>déconnecter</Button> 
                </Stack>
            </Container>
        )
    }
}


export default SupabaseAuth