import React, { useState } from 'react';


import { Button, Container, Table } from 'react-bootstrap';

import DefineTypeRDV from '../modules/TypeRdvDefintion';
import { timeofday } from '../components/utils'
import { ResetPasswordPage } from './reset_password';

type timeofdayType = {
    value: string,
    label: string, 
    start: string,
    end: string,
}

const prod = true

export const ProfileParameters = () => {

    const table_row = (values:timeofdayType) => {
        return (
            <tr>
                <td>{values['label']}</td>
                <td>{values['start']}</td>
                <td>{values['end']}</td>
            </tr>
        )
    }


    return (
        <Container>
            { prod ? ( 
                <Container> 
                     <h1 style={{margin: '2rem'}}>Page en cours de construction</h1> 
                     <h3> Changer de mot de passe </h3>
                     <ResetPasswordPage />
                </Container> 
            ) : (
                <Container>
            <DefineTypeRDV/>
            <p> Cela va nous permettre de trouver les créneaux qui vous conviennent vraiment </p>

            <h3> Définir les priorités des routines </h3>
            <p> Pour vous aider à trouver des liens entre les </p>

            <h3> Mes rappels </h3>
            <Button> M'envoyer une notification de bilan de la journée à venir </Button>
            <p> le matin ou le soir ? </p>

            <Button> M'envoyer un rappel 15 minutes avant de partir </Button>
            <p> Me prévenir utiliser : </p>
            <select>
                <option value="sms">sms</option>
                <option value="appel téléphonique">téléphone</option>
                <option value="whatsapp">whatsapp</option>
                <option value="mail">mail</option>
                <option value="autre">aucun</option>
            </select>

            <h3> Transport favori</h3>
            <p> par défaut, nous choisirons ce moyen de transport pour calculer votre trajet et prévoir les rappels aux bons moments </p>
            <p> Vous pouvez bien sûr changer le moyen de transport à tout moment </p>
            {/* <Select options={["vélo", "transport en commun", "taxi", "uber", "autre"]}
                // getOptionLabel={(user: UserType) => user.name}
                // getOptionValue={(user: UserType) => user.user_id}
                isClearable={true}
                // onChange={onChange}
            /> */}
            <select>
                <option value="à pied">à pied</option>
                <option value="vélo">vélo</option>
                <option value="transport">transport en commun</option>
                <option value="taxi">taxi / uber </option>
                <option value="autre">autre</option>
            </select>

            <h3> Lieu par défaut </h3>
            <p> Mettre des lieux diffrents en fonction des horaires </p>


            <h3> Mon vocabulaire </h3>
            <p> Spécialiste du métier, notre expérience nous a fait définir des créneaux standard. Toutefois, vous avez votre propre rythme et vous pourrez prochainement
                changer les défintions ci-dessous.
            </p>
            <Table>
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th>Heure de début</th>
                    <th>Heure de fin</th>
                    </tr>
                </thead>
                <tbody>
                    {timeofday.map(table_row)}
                </tbody>
            </Table>

            <ResetPasswordPage />
            </Container>
        )}
            
        </Container>
    )
}
