import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useNavigate } from "react-router-dom";

import { supabase } from './auth/supabaseClient';
import { useEffect, useState } from 'react';

import SupabaseAuth from './auth/SupabaseAuth';
import { Link } from 'react-router-dom';

import logo from "../favicon-128.png"

function NavbarInvited() {

    // utiliser le offcanva, c'est cool
    // la session étant gérée par ailleurs, ce n'est peut-être pas nécessaire de l'avoir
    const [session, setSession] = useState<null | any >(null)

    let navigate = useNavigate();

    const routeChange = () =>{ 
      let path = `/invitation`; 
      navigate(path);
    }

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
        })

        const {
        data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    return (
        <>
        <Navbar bg="primary" variant="dark" expand="lg" style={{ fontSize: "large", justifyContent: 'center', alignContent:'center'}}>
            <Container>
                <Navbar.Brand style={{alignContent:'center'}} as={Link} to='/'>
                    <img
                        alt=""
                        src= {logo}
                        // src="../datetime/favicon-128.png"
                        width="70"
                        height="70"
                        className="d-inline-block align-center"
                    />
                    {' '}
                    DaTime <br></br> 
                    
                    Trouve pour vous des rendez-vous qui vous conviennent
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="container-fluid">
                        <Nav.Link as={Link} to="/invitation">Définir et envoyer mes disponibilités</Nav.Link> 
                    </Nav>
                    <Nav className="container-fluid">
                        <Nav.Link as={Link} to="/invitation/import">Importer mes agendas</Nav.Link>
    
                    </Nav>
                    <Nav>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Nav.Link as={Link} to="/faq">F.A.Q</Nav.Link>
                        <Nav.Link as={Link} to="/datime" > En savoir plus sur DaTime</Nav.Link>
                        {/* <Navbar.Text >
                            <SupabaseAuth></SupabaseAuth>
                        </Navbar.Text> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>   
        </>
    );
}


export default NavbarInvited;