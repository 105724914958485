import { helperPostChronopt } from './post_chronopt_helper'

type basicSlot = {
    start:string,
    end:string
}

export const postIsFree = async (meeting_id: string, slots: basicSlot[]) => {
    const json = await helperPostChronopt( {
        url_slash : '/isFree',
        json: JSON.stringify( { meeting_id : meeting_id, slots: slots} )
        } 
    );

    return json
} 
