import { configureStore } from '@reduxjs/toolkit'
import { StateReducer} from "./EventsReducer"
import { PropositionsReducer } from "./PropositionsReducer"
import MeetingReducer from "./MeetingReducer"
import DatersReducers from "./DatersReducer"
import weekendsVisibleReducer from "./WeekendReducer"
import dayslotsReducer from "./DaySlotsReducer"
import kindOfRdvReducer from "./KindOfRdvReducer"
import AuthReducer from './SessionReducer'
import ProfileReducer from './ProfileReducer'
import InvitedReducer from './InvitedReducer'
import { DebaucheReducer, EmbaucheReducer, LunchReducer } from './AgendaReducer'


const store = configureStore({
    reducer: {
        weekendsVisible: weekendsVisibleReducer,
        events: StateReducer,
        propositions: PropositionsReducer,
        meeting: MeetingReducer,
        invited: InvitedReducer,
        // meetings: MeetingsReducer,
        daters: DatersReducers,
        dayslots: dayslotsReducer,
        kindOfRdv: kindOfRdvReducer,
        auth: AuthReducer,
        profile: ProfileReducer,
        lunch: LunchReducer,
        embauche: EmbaucheReducer,
        debauche: DebaucheReducer,
        }
    })


export default store;

    
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch