import Select, { MultiValue, ActionMeta, SingleValue } from 'react-select'
import { timeofday } from './utils'
import { Button } from 'react-bootstrap'
import { DaterType } from '../types/Dater'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExcludedSlotsSelector, MeetingSelector, PropositionsSelector, UserIdSelector } from "../store/selectors"
import { eventAgendIAtype, postAgendIAGetCreneau, requestDataType } from '../api/agendIA'
import type {AppDispatch} from '../store/store'
import { addProposition } from '../store/PropositionsReducer'
import { TypeProposition } from '../types/Propositions'


export const GetCreneau = () => {
    
    // const [creneau, setCreneau] = useState<TypeProposition[]>([]);
    const propositions = useSelector(PropositionsSelector)
    const bh = useSelector(ExcludedSlotsSelector)
    const dispatch = useDispatch<AppDispatch>()
    const user_id = useSelector(UserIdSelector)

    const meeting = useSelector(MeetingSelector)

    const onRequest = async () => {

        const data_to_send = {
            userId: user_id,
            duree: meeting.duration,
            plage: 12,
            nb_choices: 5,
            excluded_slots : bh,
            excluded_days: []
        } as requestDataType

        console.log("le meeting que l'on met c'est", meeting)
        await postAgendIAGetCreneau(data_to_send).then(
            reponse_json => {
                if (reponse_json){
                    reponse_json.map((item:TypeProposition) => {
                        // console.log('un item recupéré', item)
                        dispatch(addProposition({...item, meeting_id:meeting.id}))
                        }
                    )
                }
            }
        )
    }

    return (
        <>
            <Button id='send_to_agendIA' onClick={onRequest}>
                <p data-align="right" data-background='red'>
                    <strong> Afficher les créneaux suggérés par DaTime </strong>
                </p>
            </Button>
        </>
        
    )
}