import { supabase } from '../components/auth/supabaseClient';
import type { TypeProposition } from '../types/Propositions';

export const DBcreateProposition = async (plainPropositionObject: TypeProposition) => {
    const { data, error } = await supabase
        .from('propositions')
        .upsert(plainPropositionObject, { onConflict: 'id' })
    if (error) { throw { error }; } 
}


export const DBupdateProposition = async (plainPropositionObject: TypeProposition) => {
    const { data, error } = await supabase
        .from('propositions')
        .update({ ...plainPropositionObject })
        .eq('id', plainPropositionObject.id)

    if (error) { throw { error }; }
}


export const DBrequestsPropositions = async (meeting_id: string) => {
    const { data, error } = await supabase
        .from('propositions')
        .select('id')
        .eq('meeting_id', meeting_id)
        .returns<TypeProposition[]>();

    if (error) { throw { error }; }

    return data
}


export const DBdeleteProposition = async (propId: string) => {
    const { data, error } = await supabase
        .from('propositions')
        .delete()
        .eq('id', propId)
        
    if (error) { throw { error }; }
}


export const DBrequestPropositionsOfMeeting = async (meeting_id:string) => {
    // avant on cherchait un event mais 
    // const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
    // if (errorAuth){
    //     console.log('Probleme dans getUser', errorAuth)
    // }
    // const userId = dataAuth.user?.id
    const { data: dataProposition, error:errorProposition } = await supabase
        .from('propositions')
        .select()
        .eq('meeting_id', meeting_id)
        .returns<TypeProposition[]>();

    if (errorProposition) { throw { errorProposition }; }

    return dataProposition
}

export const initialize_to_accepted = (proposition:TypeProposition, user_id: string): TypeProposition => {
    let condition = proposition['validated_by'].includes(user_id) || proposition['possible_by'].includes(user_id) || proposition['rejected_by'].includes(user_id)
    if (!condition) {
        return {...proposition, validated_by: [...proposition['validated_by'], user_id]}
    }
    if ( ! proposition['submitted_to'].includes(user_id) ) {
        console.log('on n a pas user_id')
        return {...proposition, submitted_to: [...proposition['submitted_to'], user_id]}
    } else { console.log('bizarre, on est là') }
    
    return proposition
}

export const adapt_avalability = (proposition:TypeProposition, user_id: string): TypeProposition => {
    let new_avalability = user_id in proposition['rejected_by'] ? 0 : ( user_id in proposition['possible_by'] ? 0.5 : 1)
    return {...proposition, availability: new_avalability}
}

export const DBrequestPropositionsForUserOfMeeting = async (user_id: string, meeting_id:string) => {
    // avant on cherchait un event mais 
    // const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
    // if (errorAuth){
    //     console.log('Probleme dans getUser', errorAuth)
    // }
    // const userId = dataAuth.user?.id
    const { data: dataProposition, error:errorProposition } = await supabase
        .from('propositions')
        .select()
        .eq('meeting_id', meeting_id)
        .returns<TypeProposition[]>();

    if (errorProposition) { throw { errorProposition }; }

    console.log('in_request', dataProposition)
    return dataProposition
}


export const DBrequestUserPropositionsSubmitted = async (range: any, userId:string) => {
    // avant on cherchait un event mais 
    // const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
    // if (errorAuth){
    //     console.log('Probleme dans getUser', errorAuth)
    // }
    // const userId = dataAuth.user?.id
    const { data: dataProposition, error:errorProposition } = await supabase
        .from('propositions')
        .select()
        .in('submitted_to', [userId])
        .returns<TypeProposition[]>();

    if (errorProposition) { throw { errorProposition }; }

    return dataProposition
}