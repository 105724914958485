import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// // import reportWebVitals from './reportWebVitals';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import './theme/custom.scss';

import store from "./store/store";
import AppPro from './App_pro'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <AppPro/>
    </Provider>,
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorkerRegistration.register();