import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container } from "react-bootstrap";
import { supabase } from "../components/auth/supabaseClient";


// const url = 'http://localhost:5000'
const url = 'https://datime.io/api'
const originURL = process.env.REACT_APP_URL_Origin as string;

export const TestAPIPage = () => {

    const testApiRDV = async () => {
        await fetch(url + '/creneau', {
            headers : {
                "Content-Type": "application/json",
                "Origin": originURL
            },
            method: "POST",
            mode: "cors", // no-cors, *cors, same-origin
            // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "omit", // include, *same-origin, omit
            // Access-Control-Allow-Origin: http://localhost:3000
            // redirect: "follow", // manual, *follow, error
            // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({userId: "419e7688-9954-4cb2-be91-c693b0f98d54", duree: 60, plage:15, nb_choices:5, excluded_slots:[]}), // body data type must match "Content-Type" header
        }).then(response => console.log(response))
    }

    const testApiTest = async () => {
        await fetch( url + '/test?word=on_est_bien',  {
            headers : {
            },
            method: "GET"
        }).then(response => console.log(response))
    }

    return (
        <Container >
            <Col md={{ span: 4, offset: 4 }}>
                <Button onClick={testApiTest}> API test </Button>
            </Col>

            <Col md={{ span: 4, offset: 4 }}>
                <Button onClick={testApiRDV}> API </Button>
            </Col>
        </Container>
    );
}
