import { createSlice } from '@reduxjs/toolkit'
import { rdvType } from '../components/utils';
import { KindOfRdvType } from '../types/TimeRange';


type payloadAddType = {
    payload: {
        new_rdvType: KindOfRdvType
    }
}

type payloadRemoveType = {
  payload: {
      value: number,
  }
}

const KindOfRdvSlice = createSlice({
  name: "KindOfRdv",
  initialState: rdvType,
  reducers: {
    addKindOfRDV: (rdvTypes, action: payloadAddType) => {
        let new_rdvType = rdvTypes
        new_rdvType.push(action.payload.new_rdvType)
        return new_rdvType
    },
    removeKindOfRDV: (rdvTypes, action: payloadRemoveType) => {
      let new_rdvType = rdvTypes.filter((item:KindOfRdvType) => item.value != action.payload.value)
      return new_rdvType
  },
  }
});


const { actions, reducer } = KindOfRdvSlice 

export const { addKindOfRDV, removeKindOfRDV } = actions
export default reducer
