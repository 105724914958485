import { KindOfRdvType } from "../types/TimeRange"

export type ValueLabel = {
    readonly value: string,
    readonly label: string
}

export const daysofweek : ValueLabel[] = [
    { value: '1', label: 'Lundi' },
    { value: '2', label: 'Mardi' },
    { value: '3', label: 'Mercredi' },
    { value: '4', label: 'Jeudi' },
    { value: '5', label: 'Vendredi' },
    { value: '6', label: 'Samedi' },
    { value: '0', label: 'Dimanche' }
]

export const label_of_day = { 
    '1': 'Lundi',
    '2': 'Mardi',
    '3': 'Mercredi',
    '4': 'Jeudi',
    '5': 'Vendredi',
    '6': 'Samedi',
    '0': 'Dimanche'
} as const

export const label_of_day_period = { 
    '0': {label: 'Matin', start: "08:00:00", end: '11:30'},
    '1': {label: 'Midi', start: "11:30:00", end: '14:00'},
    '2': {label: 'Soir', start: "14:00:00", end: '21:30'},
    '3': {label: 'Soir tard', start: "22:00:00", end: '23:30'},
}


export const timeofday = [
    { value: '0', label: 'en début de journée', start:"08:00", end:"09:30" },
    { value: '1', label: 'en matinée', start:"09:30", end:"11:30" },
    { value: '2', label: 'en fin de matinée', start:"11:00", end:"12:30" },
    { value: '3', label: 'à la pause déjeuner', start: "11:30", end: '14:00'},
    { value: '4', label: "dans l'après-midi", start: "14:00", end: '18:00'},
    { value: '5', label: "en fin d'après-midi", start: "17:30", end: '19:00'},
    { value: '6', label: 'en début de soirée',  start: "18:30", end: '21:00'},
    { value: '7', label: 'en soirée', start: "19:30", end: '23:00'},
    { value: '8', label: 'la nuit', start: "22:00", end: '08:00'},
] as const

const optionsRdv = ["petit-déjeuner", "déjeuner", "un verre", "un diner"]

export const rdvType : KindOfRdvType[] = [
    // { value: '0', label: 'rendez-vous', start:"08:00", end:"09:30" },
    { value: 1, label: 'une réunion classique hors déjeuner', times : [{ start:"08:30", end:"13:00"}, { start:"14:00", end:"19:30"}] },
    { value: 2, label: 'un déjeuner', times : [{ start:"11:00", end:"14:30" }] },
    { value: 3, label: 'petit-déjeuner', times : [{ start:"7:30", end:"9:30" }] },
    { value: 4, label: 'un échange en fin de journée', times : [{ start:"17:30", end:"20:30" }] },
    { value: 5, label: 'une réunion classique déjeuner compris', times : [{ start:"08:30", end:"19:30" }] },

]
  
export function reportNetworkError() {
    alert('This action could not be completed')
}
    