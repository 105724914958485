import React, { ChangeEventHandler, useEffect, useState } from "react"
import { Button, Table, ProgressBar, Form, Container, Row, Col } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";
import { MeetingActions } from '../../store/MeetingReducer';
import { InvitedMeetingSelector, MeetingSelector } from "../../store/selectors";
import { AppDispatch } from '../../store/store';
import { MeetingDurationSelect } from "./selectDuration";

import { fr } from 'date-fns/locale/fr';
import { InvitedActions } from "../../store/InvitedReducer";
registerLocale('fr', fr)


export const MeetingForm = () => {

    const localMeetingPage = useSelector(MeetingSelector)
    const myMeeting = useSelector(InvitedMeetingSelector)
    const dispatch = useDispatch<AppDispatch>()

    const [limitDate, setLimitDate] = useState<Date>(new Date());
    const [query, setQuery] = useState("");
    const [location, setLocation] = useState("");

    useEffect(() => {
        const timeOutId = setTimeout(() => {
          let newMeeting = {
            ...localMeetingPage, 
            "object": query
        }
        dispatch(MeetingActions.createMeeting(newMeeting))
        }, 1500);
        return () => clearTimeout(timeOutId);
    }, [query]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
          let newMeeting = {
            ...localMeetingPage, 
            "location": location
        }
        dispatch(MeetingActions.createMeeting(newMeeting))
        }, 1500);
        return () => clearTimeout(timeOutId);
    }, [location]);


    useEffect(() => {
        let formatted_date = limitDate.toISOString().split('T')[0]
        dispatch(MeetingActions.createMeeting({ ...localMeetingPage, "limitDate": formatted_date }))
        dispatch(InvitedActions.createInvited({ ...myMeeting, "limitDate": formatted_date }))
    }, [limitDate]);
    
    
    console.log('localMeetingPage', localMeetingPage)
    return (
        <Form>
            <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>
                Objet de la réunion
            </Form.Label>
            <Col sm={7}>
                <Form.Control
                    type="text"
                    id="inputObjectMeeting"
                    value={query}
                    placeholder= {localMeetingPage['object'] === '' ? "Nom public de la réunion" : localMeetingPage['object']}
                    onChange={e => {
                        setQuery(e.target.value)

                    }}
                />
            </Col>
            </Form.Group>
    
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
            <Form.Label column sm={3}>
                Type de réunion
            </Form.Label>
            <Col sm={7}>
                {/* <Form.Control type="password" placeholder="Password" /> */}
                <Form.Select aria-label="Default select example" id="inpuTypeMeeting"
                    onChange={(event: any) => {
                        dispatch(MeetingActions.createMeeting({ ...localMeetingPage, "simpleType": event.target.value }))
                        dispatch(InvitedActions.createInvited({ ...myMeeting, "simpleType": event.target.value  }))
                    }}
                >
                    <option>Type de réunion</option>
                    <option value="normal">Réunion classique</option>
                    <option value="dej">Déjeuner</option>
                    <option value="soirée">Fin de journée</option>
                    <option value="normal+dej">Réunion classique ou déjeuner</option>
                </Form.Select>
            </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={3}>
                Durée de la réunion
                </Form.Label>
                <Col sm={7}>
                    <MeetingDurationSelect/>
                </Col>
            </Form.Group>
            <hr />
            <Row style={{justifyContent:"left", color:"red"}}>Optionnel</Row>

            <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={3}>
                La réunion doit être placée à partir de :
                </Form.Label>
                <Col sm={7}>
                    <Form.Select aria-label="Default select example" id="inpuTypeMeeting" 
                    onChange={(event: any) => {
                        var result = new Date();
                        if (event.target.value === "1") { result.setDate(result.getDate() + (((1 + 7 - result.getDay()) % 7) || 7)); }
                        if (event.target.value === "2") { result.setDate(result.getDate() + (((1 + 7 - result.getDay()) % 7) || 7) + 7); }
                        if (event.target.value === "3") { result.setDate(result.getDate() + 28); }
                        let formatted_date = result.toISOString().split('T')[0]
                        dispatch(MeetingActions.createMeeting({ ...localMeetingPage, "startDate": formatted_date }))
                        dispatch(InvitedActions.createInvited({ ...myMeeting, "startDate": formatted_date  }))
                    }}
                    >
                        <option>maintenant</option>
                        <option value="1">la semaine prochaine</option>
                        <option value="2">la semaine encore prochaine</option>
                        <option value="3">dans un mois</option>
                    </Form.Select>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={3}>
                La réunion doit absoluement se tenir avant :
                </Form.Label>
                <Col sm={7}>
                    <DatePicker selected={limitDate} onChange={(date: Date) => setLimitDate(date)} locale={'fr'} dateFormat={["P"]}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                    Lieu
                </Form.Label>
                <Col sm={7}>
                    <Form.Control
                        type="text"
                        id="inputObjectMeeting"
                        value={location}
                        placeholder= {''}
                        onChange={e => {
                            setLocation(e.target.value)
    
                        }}
                    />
                </Col>
            </Form.Group>

        </Form>
    )
}