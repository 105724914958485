import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { supabase } from '../components/auth/supabaseClient';
import type { MeetingType } from '../types/Meeting';
import { InvitedMeetingType, InvitedType } from '../types/Dater';

import { createId } from '../api/createId';
const default_type_RDV = {
    value: 10,
    label: 'type_de_rdv',
    times: [{start: "09:30:00", end: "12:30:00", tz: "UTC"}]
}
const unset_meeting = { 
    id: createId(),
    created_by: null,
    simpleType: 'normal',
    type: default_type_RDV,
    duration: 60,
    participants: [],
    location: '',
    object:'Nom public de la réunion'
} as MeetingType


const MeetingSlice = createSlice({
    name: "meetingSlice",
    initialState: unset_meeting as MeetingType,
    reducers: {
        createMeeting: (meeting, action) => {
            return action.payload
        },
        
        // updateMeeting: (meeting, action) => {
        //     if (meeting) {
        //         return action.payload.meeting
        //     } else {
        //         throw new Error ("Impossible d'updater un meeting qui n'est pas chargé")
        //     }
        // },
        
        deleteMeeting: () => {
            return unset_meeting
        },

        addParticipant: (meeting, action) => {
            if (meeting) {
                meeting['participants'] = [...meeting['participants'], action.payload.invited]
                return meeting
            } else {
                throw new Error ("Impossible d'ajouter un participant à un meeting qui n'est pas chargé")
            }
        },

        updateParticipant: (meeting, action) => {
            if (meeting) {
                let other_participants = meeting['participants'].filter( (item : InvitedMeetingType) => item.id !== action.payload.invited.id)
                meeting['participants'] = [...other_participants, action.payload.invited]
                return meeting
            } else {
                throw new Error("Impossible de modifier un participant à un meeting qui n'est pas chargé")
            }
        },

        deleteParticipant: (meeting, action) => {
            if (meeting) {
                meeting['participants'] = meeting['participants'].filter((item : InvitedMeetingType) => item.id !== action.payload.id_person)
                return meeting
            } else {
                throw new Error ("Impossible de retier un participant à un meeting qui n'est pas chargé")
            }
        },

        requestUserMeetings: (meeting, action) => {
            return action.payload.plainMeetingObjects
        },

        requestMeeting: (meeting, action) => {
            return action.payload.plainMeetingObjects
        },

        requestIdMeeting: (meeting, action) => {
            return action.payload.plainMeetingObjects
        },

        flushMeetings: (meeting) => {
            return unset_meeting
        }
    }
});


export const { actions: MeetingActions, reducer: MeetingReducer } = MeetingSlice 

export const { createMeeting, deleteMeeting, requestUserMeetings, requestIdMeeting, flushMeetings } = MeetingActions

export default MeetingSlice.reducer