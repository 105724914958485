import Select, { MultiValue, ActionMeta, SingleValue } from 'react-select'
import { InvitedMeetingSelector, MeetingSelector, UserSelector } from '../../store/selectors';
import { useDispatch, useSelector } from 'react-redux'
import { MeetingActions } from '../../store/MeetingReducer';
import { InvitedActions } from '../../store/InvitedReducer';
import { timeofday } from '../../components/utils'
import { AppDispatch } from '../../store/store';
import { Form } from 'react-bootstrap';


export const durations = [
    { value: '0', label: "15 minutes", minutes: 15 },
    { value: '1', label: "20 minutes", minutes: 20 },
    { value: '2', label: "30 minutes", minutes: 30 },
    { value: '3', label: '45 minutes', minutes: 45 },
    { value: '4', label: "1 heure", minutes: 60 },
    { value: '5', label: "1 heure 30", minutes: 90 },
    { value: '6', label: '2 heures', minutes: 120 },
    { value: '7', label: "2 heures 30", minutes: 150 },
    { value: '8', label: "3 heures", minutes: 180 },
    ] as const


export const MeetingDurationSelect = () => {
    const localMeetingPage = useSelector(MeetingSelector)
    const myMeeting = useSelector(InvitedMeetingSelector)
    const dispatch = useDispatch<AppDispatch>()
    
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log('event onChange', event)
        dispatch(MeetingActions.createMeeting({ ...localMeetingPage, "duration": parseInt(event.target.value) }))
        dispatch(InvitedActions.createInvited({ ...myMeeting, "duration": parseInt(event.target.value)  }))
    }
    return (

        <Form.Select aria-label="Default select example" id="inpuTypeMeeting"
            onChange={ e => { onChange(e) }}
        >
            { durations.map(item => {
                return <option value={item['minutes']} key={item['value']}> { item['label'] } </option>
            })}
        </Form.Select>

        // <Select options={durations}
        //     defaultValue={{ value: '4', label: "1 heure", minutes: 60 }}
        //     onChange={value => value ? onChange(value) : null }
        //     classNamePrefix="react-select"
        //     className="react-select--inline"
        //     components={{
        //         IndicatorsContainer: () => null
        //       }}
        //     styles={{
        //     control: (baseStyles, state) => ({
        //         ...baseStyles,
        //         display: "inline-block",
        //         //width: '280px',
        //         outline: "none"
        //         // borderColor: state.isFocused ? 'grey' : 'red',
        //         })
        //     }}
        // />
    )
}